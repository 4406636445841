/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "ap-northeast-1",
    "aws_cloud_logic_custom": [
        {
            "name": "AdminQueries",
            "endpoint": "https://t8v9bacbi4.execute-api.ap-northeast-1.amazonaws.com/stagingtwo",
            "region": "ap-northeast-1"
        },
        {
            "name": "commandApi",
            "endpoint": "https://8onctmua9i.execute-api.ap-northeast-1.amazonaws.com/stagingtwo",
            "region": "ap-northeast-1"
        },
        {
            "name": "stripeWebhook",
            "endpoint": "https://1a1yqck3of.execute-api.ap-northeast-1.amazonaws.com/stagingtwo",
            "region": "ap-northeast-1"
        }
    ],
    "aws_appsync_graphqlEndpoint": "https://fiwwt4h3prc6vlcl7grffpg7vy.appsync-api.ap-northeast-1.amazonaws.com/graphql",
    "aws_appsync_region": "ap-northeast-1",
    "aws_appsync_authenticationType": "AMAZON_COGNITO_USER_POOLS",
    "aws_appsync_apiKey": "da2-r2csd3tatjfr7nxupnezv5lveu",
    "aws_cognito_identity_pool_id": "ap-northeast-1:48fb5e64-bd6a-469c-b1be-7bdb12dc5879",
    "aws_cognito_region": "ap-northeast-1",
    "aws_user_pools_id": "ap-northeast-1_Suk0K4eYH",
    "aws_user_pools_web_client_id": "6biitkdg787mrc1b9s9pkrikbh",
    "oauth": {},
    "aws_cognito_username_attributes": [],
    "aws_cognito_social_providers": [],
    "aws_cognito_signup_attributes": [
        "EMAIL"
    ],
    "aws_cognito_mfa_configuration": "OFF",
    "aws_cognito_mfa_types": [
        "SMS"
    ],
    "aws_cognito_password_protection_settings": {
        "passwordPolicyMinLength": 8,
        "passwordPolicyCharacters": []
    },
    "aws_cognito_verification_mechanisms": [
        "EMAIL"
    ],
    "aws_user_files_s3_bucket": "badesk-storage80941-stagingtwo",
    "aws_user_files_s3_bucket_region": "ap-northeast-1",
    "aws_appsync_apiTimeout:": "90000"
};


export default awsmobile;
